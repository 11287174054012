<template>
  <div class="turnoverTab">
    <el-button type="primary" style="width: 88px;margin-left: 18px;margin-top: 5px;z-index: 99999" size="small"
               @click="fanhui">
      返回上级
    </el-button>
    <div class="t-member" style="margin-top: -18px">
      <span class="t-span">客户: {{order.memberName==null?'散客':order.memberName}} {{order.cardName==null?'':'('+order.cardName+')'}}</span>
      <span class="t-span" v-if="order.phone">手机号: {{order.phone}}</span>
      <span class="t-span">客户归属: {{order.deptName==null?deptName:order.deptName}}</span>
      <span class="t-span">消费时间: {{order.createTime}}</span>
    </div>
    <div class="t-list">
      <div class="t-xf">
        <span>消费信息</span>
      </div>
      <div style="width:98%;margin: auto;margin-top: 18px;">
        <table
          cellspacing="0"
          border="0"
          width="100%"
          style="text-align: center"
        >
          <thead>
          <tr style="background-color: #F7F7F7;border:#EEEEEE solid 1px;">
            <th class="tab-th">名称</th>
            <th class="tab-th">类型</th>
            <th class="tab-th">金额</th>
            <th class="tab-th">员工</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in orderInfos">
            <td class="t-td-l" style="border-left:#EEEEEE solid 1px;">
              {{item.commodityName}}
            </td>
            <td class="t-td-l">
              <span v-if="item.commodityType==6">开支</span>
              <span v-if="item.commodityType==7">提现</span>
              <span v-if="item.commodityType==8">借支</span>
              <span v-if="item.commodityType==9">收入</span>
            </td>
            <td class="t-td-l">{{item.price}}</td>
            <td class="t-td-l">
              <span v-if="item.empCg">{{item.empCg.empName}}</span>
              <span v-else>暂无</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import {getSwName, getToken} from "../../utils/auth";

  export default {
    name: "orderInfo",
    data() {
      return {
        deptName: getSwName(),
        orderId: '',
        orderInfoId: '',
        ach: 0,
        order: {},
        orderInfos: [],
        empVisible: false,

        plabsList: [],
        plabsId: '',
        empList:[],
        empId:'',

        form: {},
        appoint: 1,
      };
    },
    async created() {
      this.orderId = this.$route.query.orderId
      this.getOrderInfo()
    },

    methods: {

      fanhui() {
        this.$router.go(-1)
      },
      async getOrderInfo() {
        let res = await this.$get("/sw/getSwOrderDetails", {orderId: this.orderId});
        this.order = res.order
        this.orderInfos = res.syOrderInfos
      },
      addEmp(orderInfoId) {
        this.form={}
        this.empVisible = true
        this.orderInfoId = orderInfoId
      },
      async saveEmp() {
        this.empVisible = false
      },
    },
    components: {},
  };
</script>


<style lang="scss" scoped>

  .turnoverTab {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .t-member {
    display: flex;
    flex-direction: column;
    height: 23%;
    justify-content: center;
    justify-items: center;
  }

  .t-span {
    width: 100%;
    height: 28px;
    line-height: 28px;
    margin-left: 55px;
  }

  .t-list {
    display: flex;
    flex-direction: column;
    overflow:scroll
  }

  .t-xf {
    height: 28px;
    line-height: 28px;
    background-color: #F7F7F7;
    padding-left: 20px;
  }

  .t-xf span {
    border-left: #2b94ff solid 2px;
    padding-left: 5px;
    font-weight: bold;
  }

  .tab-th {
    width: 12%;
    height: 38px;
    line-height: 38px;
    font-weight: bold;
  }

  .table-responsive {
    border: 2px solid rgb(40, 40, 40, .5);
    color: rgb(40, 40, 40);
  }

  .t-td-l {
    padding: 0;
    height: 30px;
    line-height: 30px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    border-right: #EEEEEE solid 1px;
    border-bottom: #EEEEEE solid 1px;
    font-size: 13px;
  }

  .empac {
    border-top: #EEEEEE solid 1px;
  }

  .empac1 {
    border-top: #EEEEEE solid 0px;
  }
</style>
